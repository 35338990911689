import "./App.css";
import "graphiql/graphiql.css";
import "@shopify/polaris/build/esm/styles.css";

import { useState, useMemo, useCallback, useEffect } from "react";
import { Button, TextField, InlineStack, BlockStack, Card, InlineGrid, Text } from "@shopify/polaris";
import ReactJson from "react-json-view";
const ENDPOINT_BASE = process.env.REACT_APP_ENDPOINT_BASE;

const API_VERSION = "2025-01"; // update MINIMUM_API_VERSION in backend/index.js

function PrettyOrText({ value }) {
  const parsedObject = useMemo(() => {
    try {
      return JSON.parse(value);
    } catch {
      return null;
    }
  }, [value]);
  return parsedObject ? <ReactJson src={parsedObject} /> : <pre>{value}</pre>;
}

const ObjectViewer = ({ result }) => (result ? <ReactJson src={result} /> : null);

function SimpleLookup({ inputLabel, actionLabel, inputPlaceholder = "", action }) {
  const [input, setInput] = useState("");
  const [result, setResult] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(null);
  const [nextCursor, setNextCursor] = useState(null);

  const [hasPrevPage, setHasPrev] = useState(null);
  const [prevCursor, setprevCursor] = useState(null);

  const resParser = useCallback((res) => {
    const key = Object.keys(res)[0];

    if (res[key]?.["pageInfo"]["hasNextPage"] && res[key]["pageInfo"]["hasNextPage"]) {
      setNextCursor(res[key]["pageInfo"]["endCursor"]);
      setHasNextPage(res[key]["pageInfo"]["hasNextPage"]);
    } else {
      setHasNextPage(false);
      setNextCursor(null);
    }

    if (res[key]?.["pageInfo"]["hasPreviousPage"] && res[key]["pageInfo"]["hasPreviousPage"]) {
      setprevCursor(res[key]["pageInfo"]["startCursor"]);
      setHasPrev(res[key]["pageInfo"]["hasPreviousPage"]);
    } else {
      setHasPrev(false);
      setprevCursor(null);
    }

    setResult(res[key]["nodes"]);
  }, []);

  return (
    <BlockStack gap="400">
      <TextField
        label={inputLabel}
        value={input}
        onChange={(value) => {
          let strippedValue = value;
          strippedValue = strippedValue.replaceAll("'", "").replaceAll('"', "");
          setInput(strippedValue);
        }}
        placeholder={inputPlaceholder}
        connectedRight={
          <Button
            onClick={async () => {
              const res = await action({ input });
              resParser(res);
            }}
          >
            {actionLabel}
          </Button>
        }
      />
      <>
        {result && (
          <InlineStack>
            <Button
              disabled={!hasPrevPage}
              onClick={async () => {
                const res = await action({ input, cursor: prevCursor });
                resParser(res);
              }}
            >
              Previous Page
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              disabled={!hasNextPage}
              onClick={async () => {
                const res = await action({ input, cursor: nextCursor });
                resParser(res);
              }}
            >
              Next Page
            </Button>
          </InlineStack>
        )}
        <ObjectViewer result={result} />
      </>
    </BlockStack>
  );
}

function SimpleSubObjectLookup({ inputLabel, action, disabled }) {
  const [result, setResult] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(null);
  const [nextCursor, setNextCursor] = useState(null);

  const [hasPrevPage, setHasPrev] = useState(null);
  const [prevCursor, setprevCursor] = useState(null);

  const resParser = useCallback((res) => {
    const key = Object.keys(res)[0];

    if (res[key]?.["pageInfo"]["hasNextPage"] && res[key]["pageInfo"]["hasNextPage"]) {
      setNextCursor(res[key]["pageInfo"]["endCursor"]);
      setHasNextPage(res[key]["pageInfo"]["hasNextPage"]);
    } else {
      setHasNextPage(false);
      setNextCursor(null);
    }

    if (res[key]?.["pageInfo"]["hasPreviousPage"] && res[key]["pageInfo"]["hasPreviousPage"]) {
      setprevCursor(res[key]["pageInfo"]["startCursor"]);
      setHasPrev(res[key]["pageInfo"]["hasPreviousPage"]);
    } else {
      setHasPrev(false);
      setprevCursor(null);
    }

    setResult(res[key]["nodes"]);
  }, []);

  const doer = useCallback(async () => {
    const res = await action({});
    resParser(res);
  }, [action, resParser]);

  return (
    <BlockStack gap="200">
      <Button size="slim" disabled={disabled} onClick={doer}>
        {inputLabel}
      </Button>
      <>
        {result && (
          <InlineStack>
            <Button
              disabled={!hasPrevPage}
              onClick={async () => {
                const res = await action({ cursor: prevCursor });
                resParser(res);
              }}
            >
              Previous Page
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              disabled={!hasNextPage}
              onClick={async () => {
                const res = await action({ cursor: nextCursor });
                resParser(res);
              }}
            >
              Next Page
            </Button>
          </InlineStack>
        )}
        <ObjectViewer result={result} />
      </>
    </BlockStack>
  );
}

function OrderSection({ data, dispatch, gqlFetch, getProxyToken }) {
  return (
    <BlockStack gap="400">
      <SimpleLookup
        inputLabel="Search Criteria for Order Count"
        actionLabel="Order Count"
        action={({ input, cursor }) =>
          gqlFetch({
            query: `query ($q: String!) {
                ordersCount(query: $q) {
                  count precision
                }
              }`,
            variables: {
              q: `${input}`,
            },
            //this fudges stupid
          }).then((payload) => ({ orderCount: { nodes: [payload?.data, payload?.extensions], pageInfo: {} } }))
        }
      />
      <SimpleLookup
        inputLabel="Order Name"
        actionLabel="Find Order by Name"
        action={({ input, cursor }) =>
          gqlFetch({
            query: `query ($q: String!) {
                orders(first: 50, query: $q) {
                  nodes {
                   id
                   name
                   createdAt
                  }
                  pageInfo { endCursor hasNextPage hasPreviousPage startCursor }
                }
              }`,
            variables: {
              q: `name:${input}`,
              cursor,
            },
          }).then((payload) => payload?.data)
        }
      />
      <SimpleLookup
        inputLabel="Orders with a Sku"
        actionLabel="Find Orders with sku"
        action={({ input, cursor }) =>
          gqlFetch({
            query: `query ($q: String!) {
              orders(first: 50, query: $q) {
                nodes {
                  id
                  name
                  createdAt
                  lineItems(first: 50) {
                    nodes {
                      title
                      sku
                      quantity
                      currentQuantity
                      refundableQuantity
                      customAttributes{ key value }
                      duties {  harmonizedSystemCode  countryCodeOfOrigin price{...MoneyBag} taxLines {title rate ratePercentage priceSet{...MoneyBag}} }
                      discountedUnitPriceAfterAllDiscountsSet {...MoneyBag}
                      
                    }
                    pageInfo {
                      endCursor
                      hasNextPage
                    }
                  }
                }
                pageInfo {
                  endCursor
                  hasNextPage
                  hasPreviousPage
                  startCursor
                }
              }
            }
            fragment MoneyBag on MoneyBag {
              presentmentMoney { amount currencyCode}
              shopMoney { amount currencyCode}
            }`,
            variables: {
              q: `sku:${input}`,
              cursor,
            },
          }).then((payload) => payload?.data)
        }
      />
      <SimpleLookup
        inputLabel="Orders with Tag"
        actionLabel="Find Orders with a tag"
        action={({ input, cursor }) =>
          gqlFetch({
            query: `query ($q: String!) {
              orders(first: 50, query: $q) {
                nodes {
                  id
                  name
                  createdAt
                  tags
                }
                pageInfo {
                  endCursor
                  hasNextPage
                  hasPreviousPage
                  startCursor
                }
              }
            }`,
            variables: {
              q: `tag:${input}`,
              cursor,
            },
          }).then((payload) => payload?.data)
        }
      />
    </BlockStack>
  );
}

function ProductDisplay({ gqlFetch }) {
  const [productId, setProductId] = useState("gid://shopify/Product/");

  const [autoRefresh, setAutoRefresh] = useState(false);

  const [metafields, setMetafields] = useState(false);
  const [media, setMedia] = useState(false);

  const [variants, setVariants] = useState(null);
  const fetchVariants = useCallback(
    (cursor) => {
      const query = `{
        product(id: "${productId}"){
          variants(first:100 ${cursor ? `, after: "${cursor}"` : ""}){
            nodes {
              id
              barcode
              createdAt
              updatedAt
              inventoryQuantity
              inventoryPolicy
              taxCode
              compareAtPrice
              position
              price
              sku
              media(first: 1) { nodes { id ...on MediaImage { image { id } } } }
              taxable
              inventoryItem { id requiresShipping tracked measurement { weight { unit value } } }
              selectedOptions { name value }
              metafields(first:250) {
                nodes{
                  id
                  createdAt
                  updatedAt
                  type
                  namespace
                  key
                  value
                }
                pageInfo { hasNextPage }
              }
            }
            pageInfo { endCursor hasNextPage hasPreviousPage startCursor }
          }
        }
      }`;

      gqlFetch({
        query,
      }).then((payload) => {
        if (payload?.data.product?.variants) {
          setVariants({ ...payload?.data.product?.variants });
        } else {
          setVariants(null);
        }
      });
    },
    [gqlFetch, productId]
  );

  const activate = useCallback(() => {
    const query = `
                query {
                  product(id: "${productId}") {
                    id
                    handle
                    vendor
                    productType
                    descriptionHtml
                    templateSuffix
                    tracksInventory
                    createdAt
                    updatedAt
                    publishedAt
                    status
                    tags
                    title
                    options{
                      id
                      name
                      values
                      position
                    }
                  }
                }
                `;

    gqlFetch({
      query,
      variables: {
        id: productId,
      },
    }).then((payload) => {
      if (payload?.data?.product) {
        setResults(payload?.data?.product);
      } else {
        setResults({ error: "no product found" });
      }
    });
  }, [gqlFetch, productId]);

  useEffect(() => {
    if (autoRefresh) {
      activate();
      fetchVariants();
    }
  }, [autoRefresh, fetchVariants, productId, activate]);

  const [results, setResults] = useState(null);

  return (
    <BlockStack gap="200">
      <TextField
        label={"Product Lookup By ID"}
        value={productId}
        onChange={(value) => {
          let strippedValue = value;
          strippedValue = strippedValue.replaceAll("'", "").replaceAll('"', "");
          setProductId(strippedValue);
        }}
        connectedRight={
          <Button
            onClick={() => {
              setAutoRefresh((old) => !old);
            }}
          >
            {`${autoRefresh ? "Stop Auto-Reload" : "Search"} `}
          </Button>
        }
      />

      {results && <ReactJson src={results} />}

      {variants && (
        <BlockStack gap="200">
          <Text variant="headingMd">Variants</Text>
          <InlineStack gap="200">
            {variants.pageInfo.hasPreviousPage && (
              <Button
                onClick={() => {
                  fetchVariants(variants.pageInfo.startCursor);
                }}
              >
                Previous Page
              </Button>
            )}
            {variants.pageInfo.hasNextPage && (
              <Button
                onClick={() => {
                  fetchVariants(variants.pageInfo.endCursor);
                }}
              >
                Next Page
              </Button>
            )}
          </InlineStack>

          <ReactJson src={{ variants: variants.nodes }} />

          <SimpleSubObjectLookup
            inputLabel="Metafields"
            checked={metafields}
            setChecked={() => {
              setMetafields(!metafields);
            }}
            disabled={!autoRefresh}
            action={({ cursor }) =>
              gqlFetch({
                query: `query ($id: ID!, $cursor: String){
                  product(id: $id){
                    metafields(first:50, after: $cursor){
                      nodes{
                        id
                        createdAt
                        updatedAt
                        type
                        namespace
                        key
                        value
                      }
                      pageInfo { endCursor hasNextPage hasPreviousPage startCursor }
                    }
                  }
                }`,
                variables: {
                  id: productId,
                  cursor: cursor,
                },
              }).then((payload) => {
                if (payload.error) {
                  return { product: { nodes: { error: payload.error }, pageInfo: { hasPreviousPage: false, hasNextPage: false } } };
                } else {
                  return payload.data.product;
                }
              })
            }
          />
          <SimpleSubObjectLookup
            inputLabel="Media"
            checked={media}
            setChecked={() => {
              setMedia(!media);
            }}
            disabled={!autoRefresh}
            action={({ cursor }) =>
              gqlFetch({
                query: `query ($id: ID!, $cursor: String){
                  product(id: $id){
                    media(first: 100, query: "media_type:IMAGE", after: $cursor) { 
                      nodes {
                        id
                        ... on MediaImage {
                          id
                          status
                          createdAt
                          updatedAt
                          image {
                            id
                            url
                            altText
                            height
                            width
                          }
                        }
                      }
                      pageInfo { endCursor hasNextPage hasPreviousPage startCursor }
                    }
                  }
                }`,
                variables: {
                  id: productId,
                  cursor: cursor,
                },
              }).then((payload) => {
                if (payload.error) {
                  return { product: { nodes: { error: payload.error }, pageInfo: { hasPreviousPage: false, hasNextPage: false } } };
                } else {
                  return payload.data.product;
                }
              })
            }
          />
        </BlockStack>
      )}
    </BlockStack>
  );
}

function ProductSection({ data, dispatch, gqlFetch, getProxyToken }) {
  return (
    <BlockStack gap="200">
      <SimpleLookup
        inputLabel="SKU"
        actionLabel="Find Product and Variant ID for SKU"
        action={({ input, cursor }) =>
          gqlFetch({
            query: `query Variants($q: String!) {
                productVariants(first: 5, query: $q) {
                  nodes {
                    product {
                      id
                      title
                    }
                    id
                    title
                    sku
                  }
                  pageInfo { endCursor hasNextPage hasPreviousPage startCursor }
                }
              }`,
            variables: {
              q: `sku:${input}`,
              cursor,
            },
          }).then((payload) => payload?.data)
        }
      />
      <SimpleLookup
        inputLabel="Tag"
        actionLabel="Find Product with Tag"
        action={({ input, cursor }) =>
          gqlFetch({
            query: `query Products($q: String!, $cursor: String) {
                products(first: 5, query: $q, after:$cursor) {
                  pageInfo { endCursor hasNextPage hasPreviousPage startCursor }
                  nodes {
                      id
                      title
                      tags
                    }
                }
              }`,
            variables: {
              q: `tag:${input}`,
              cursor,
            },
          }).then((payload) => payload?.data)
        }
      />
      <SimpleLookup
        inputLabel="Vendor"
        actionLabel="Find Product by Vendor"
        action={({ input, cursor }) =>
          gqlFetch({
            query: `query Products($q: String!, $cursor: String) {
                products(first: 5, query: $q, after:$cursor) {
                  pageInfo { endCursor hasNextPage hasPreviousPage startCursor }
                  nodes {
                      id
                      title
                      vendor
                    }
                }
              }`,
            variables: {
              q: `vendor:${input}`,
              cursor,
            },
          }).then((payload) => payload?.data)
        }
      />
      <SimpleLookup
        inputLabel="Title"
        actionLabel="Find Product by Title"
        action={({ input, cursor }) =>
          gqlFetch({
            query: `query Products($q: String!, $cursor: String) {
                products(first: 5, query: $q, after:$cursor) {
                  pageInfo { endCursor hasNextPage hasPreviousPage startCursor }
                  nodes {
                      id
                      title
                    }
                }
              }`,
            variables: {
              q: `title:'${input}' OR variant_title:'${input}'`,
              cursor,
            },
          }).then((payload) => payload?.data)
        }
      />
      <ProductDisplay gqlFetch={gqlFetch} />
    </BlockStack>
  );
}

function DataBrowser({ data, dispatch, gqlFetch, getProxyToken }) {
  const [debugPath, setDebugPath] = useState("");
  const [debugText, setDebugText] = useState(null);

  const [sectionType, setSectionType] = useState("order");

  return (
    <BlockStack gap="400">
      <Card>
        <InlineGrid columns={"auto 1fr"} gap={"400"}>
          <BlockStack gap="200">
            <Button
              size="large"
              variant="secondary"
              pressed={sectionType === "order"}
              onClick={() => {
                setSectionType("order");
              }}
            >
              Orders
            </Button>
            <Button
              size="large"
              variant="secondary"
              pressed={sectionType === "product"}
              onClick={() => {
                setSectionType("product");
              }}
            >
              Products
            </Button>
          </BlockStack>
          <BlockStack>
            {sectionType === "order" && (
              <OrderSection data={data} dispatch={dispatch} gqlFetch={gqlFetch} getProxyToken={getProxyToken} />
            )}
            {sectionType === "product" && (
              <ProductSection data={data} dispatch={dispatch} gqlFetch={gqlFetch} getProxyToken={getProxyToken} />
            )}
          </BlockStack>
        </InlineGrid>
      </Card>

      <Card>
        <BlockStack gap="400">
          <InlineStack gap="400">
            <Button
              onClick={async () => {
                const sid = await getProxyToken();

                const proxyUrl = new URL(`${ENDPOINT_BASE}/shopify_api/1/admin/api/${API_VERSION}/${debugPath}`, window.location);
                const proxyResponse = await fetch(proxyUrl, {
                  headers: {
                    Authorization: `Bearer ${sid}`,
                  },
                });

                setDebugText(await proxyResponse.text());
              }}
            >
              SA Fetch
            </Button>
            <TextField value={debugPath} onChange={setDebugPath} />
          </InlineStack>

          <PrettyOrText value={debugText} />
        </BlockStack>
      </Card>
    </BlockStack>
  );
}

export default DataBrowser;
