import React, { useCallback, useContext, useReducer, useMemo, useState } from "react";
import { DEFAULT_TOAST_DURATION, Modal, Toast } from "@shopify/polaris";

const OverlayManagerContext = React.createContext(null);

const ADD = Symbol("ADD");
const REMOVE = Symbol("REMOVE");
const MODAL = Symbol("MODAL");
const TOAST = Symbol("TOAST");

function reducer({ nextId, displayList }, { action, type, props, id }) {
  if (action === ADD) {
    return {
      nextId: nextId + 1,
      displayList: [...displayList, { id: nextId, type, props }],
    };
  }

  if (action === REMOVE) {
    return {
      nextId,
      displayList: displayList.filter(({ id: innerId }) => innerId !== id),
    };
  }

  return { nextId, displayList };
}

function DisplayListModal({ dispatch, item: { id, props } }) {
  const { title, secondaryActions, render, content, sectioned = true } = props;
  const [open, setOpen] = useState(true);
  const [primaryActionInProgress, setPrimaryActionInProgress] = useState(false);
  const [secondaryActionsInProgress, setSecondaryActionsInProgress] = useState([]);

  const dismiss = useCallback(() => {
    setOpen(false); // start the close animation
    setTimeout(() => dispatch({ action: REMOVE, id }), 1000); // cleanup the modal when the animation is done
  }, [dispatch, id]);

  const anyActionInProgress = useMemo(
    () => primaryActionInProgress || secondaryActionsInProgress.some(Boolean),
    [primaryActionInProgress, secondaryActionsInProgress]
  );

  const localPrimaryAction = useMemo(() => {
    if (Object.prototype.hasOwnProperty.call(props, "primaryAction")) {
      if (props.primaryAction) {
        return {
          ...props.primaryAction,
          onAction: () => {
            setPrimaryActionInProgress(true);
            Promise.resolve(props.primaryAction.onAction?.()).finally(dismiss);
          },
          loading: primaryActionInProgress,
          disabled: props.primaryAction.disabled || anyActionInProgress,
        };
      }
      return props.primaryAction; // handle primaryAction being defined as null
    }
    return { content: "OK", onAction: dismiss };
  }, [props, dismiss, primaryActionInProgress, anyActionInProgress]);

  const localSecondaryActions = useMemo(
    () =>
      secondaryActions &&
      secondaryActions.map((secondaryAction, index) => ({
        ...secondaryAction,
        onAction: () => {
          const newSecondaryActionsInProgress = secondaryActionsInProgress.slice();
          newSecondaryActionsInProgress[index] = true;
          setSecondaryActionsInProgress(newSecondaryActionsInProgress);
          Promise.resolve(secondaryAction.onAction?.()).finally(dismiss);
        },
        loading: secondaryActionsInProgress[index],
        disabled: secondaryAction.disabled || anyActionInProgress,
      })),
    [secondaryActions, secondaryActionsInProgress, anyActionInProgress, dismiss]
  );

  return (
    <Modal
      open={open}
      onClose={dismiss}
      title={title}
      primaryAction={localPrimaryAction}
      secondaryActions={localSecondaryActions}
      sectioned={sectioned}
    >
      {render ? render(dismiss) : content}
    </Modal>
  );
}

function DisplayListToast({ dispatch, item: { id, props } }) {
  const { content, duration, error } = props;
  const dismiss = useCallback(() => {
    dispatch({ action: REMOVE, id });
  }, [dispatch, id]);

  const localDuration = Object.prototype.hasOwnProperty.call(props, "duration") ? duration : DEFAULT_TOAST_DURATION;

  return <Toast content={content} duration={localDuration} error={error} onDismiss={dismiss} />;
}

function DisplayListItem({ dispatch, item }) {
  switch (item.type) {
    case MODAL:
      return <DisplayListModal dispatch={dispatch} item={item} />;
    case TOAST:
      return <DisplayListToast dispatch={dispatch} item={item} />;
    default:
      return null;
  }
}

function OverlayManager({ children }) {
  const [{ displayList }, dispatch] = useReducer(reducer, {
    nextId: 2,
    displayList: [],
  });
  return (
    <OverlayManagerContext.Provider value={dispatch}>
      {children}
      {displayList.map((displayListItem) => (
        <DisplayListItem key={displayListItem.id} dispatch={dispatch} item={displayListItem} />
      ))}
    </OverlayManagerContext.Provider>
  );
}

export const useCreateModal = () => {
  const dispatch = useContext(OverlayManagerContext);
  return useCallback((props) => dispatch({ action: ADD, type: MODAL, props }), [dispatch]);
};

export const useCreateToast = () => {
  const dispatch = useContext(OverlayManagerContext);
  return useCallback((props) => dispatch({ action: ADD, type: TOAST, props }), [dispatch]);
};

export default OverlayManager;
