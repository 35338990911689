export const PRESET_QUERIES = {
  findProductsBySKU: {
    name: "Find Product by SKU",
    query: `query Variants($q: String!) {
  productVariants(first: 100, query: $q) {
    nodes {
      product {
        id
        title
      }
      id
      title
      sku
    }
  }
}`,
    variables: { q: "sku:FR08" },
  },

  findOrderByName: {
    name: "Find Order",
    query: `query Order($q: String!) {
  orders(first: 25, query: $q) {
    nodes {
      id
      name
    }
  }
}`,
    variables: { q: "name:1001" },
  },

  getProductById: {
    name: "Get Full Product by ID",
    query: `query ($id: ID!) {
  product(id: $id) {
    id
    handle
    vendor
    productType
    descriptionHtml
    templateSuffix
    tracksInventory
    createdAt
    updatedAt
    publishedAt
    status
    tags
    title
    options {
      id
      name
      values
      position
    }
  }
}`,
    variables: { id: "gid://shopify/Product/123" },
  },

  getActiveSubscriptions: {
    name: "Get Active Subscriptions",
    query: `{
  appInstallation {
    activeSubscriptions {
      id
      createdAt
      name
      status
      trialDays
      lineItems {
        id
        plan {
          pricingDetails {
            ... on AppRecurringPricing {
              __typename
              interval
              price {
                amount
                currencyCode
              }
            }
            ... on AppUsagePricing {
              __typename
              interval
              terms
            }
          }
        }
      }
    }
  }
}`,
    variables: {},
  },

  getOneTimePurchases: {
    name: "Get Application Charges",
    query: `{
  appInstallation {
    oneTimePurchases(first: 10) {
      nodes {
        id
        createdAt
        name
        price {
          amount
          currencyCode
        }
        status
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}`,
    variables: {},
  },

  getOrderLineItems: {
    name: "Get LineItems for Order by ID",
    query: `query OrderLineItems($id: ID!, $cursor: String) {
  order(id: $id) {
    id
    name
    poNumber
    createdAt
    updatedAt
    processedAt
    closedAt
    cancelledAt
    cancelReason
    displayFinancialStatus
    displayFulfillmentStatus
    note
    customAttributes {
      key
      value
    }
    tags
    lineItems(first: 50, after: $cursor) {
      nodes {
        id
        title
        quantity
        currentQuantity
        customAttributes {
          key
          value
        }
        originalUnitPriceSet {
          ...MoneyBag
        }
        discountedTotalSet {
          ...MoneyBag
        }
        originalTotalSet {
          ...MoneyBag
        }
        title
        taxLines {
          title
          rate
          priceSet {
            ...MoneyBag
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}

fragment MoneyBag on MoneyBag {
  presentmentMoney {
    amount
    currencyCode
  }
  shopMoney {
    amount
    currencyCode
  }
}`,
    variables: { id: "gid://shopify/Order/123" },
  },

  getOrderTransactions: {
    name: "Get Transactions for Order by ID",
    query: `query OrderTransactions($id: ID!) {
  order(id: $id) {
    id
    name
    poNumber
    createdAt
    updatedAt
    processedAt
    closedAt
    cancelledAt
    cancelReason
    displayFinancialStatus
    displayFulfillmentStatus
    note
    customAttributes {
      key
      value
    }
    tags
    transactionsCount {
      count
      precision
    }
    transactions(first: 250) {
      amountSet {
        ...MoneyBag
      }
      amountRoundingSet {
        ...MoneyBag
      }
      authorizationCode
      authorizationExpiresAt
      createdAt
      errorCode
      gateway
      id
      kind
      paymentDetails {
        __typename
      }
      processedAt
      receiptJson
      status
      totalUnsettledSet {
        ...MoneyBag
      }
    }
  }
}

fragment MoneyBag on MoneyBag {
  presentmentMoney {
    amount
    currencyCode
  }
  shopMoney {
    amount
    currencyCode
  }
}`,
    variables: { id: "gid://shopify/Order/123" },
  },

  getOrderRefunds: {
    name: "Get Refunds for Order by ID",
    query: `query OrderRefunds($id: ID!) {
  order(id: $id) {
    id
    name
    poNumber
    createdAt
    updatedAt
    processedAt
    closedAt
    cancelledAt
    cancelReason
    displayFinancialStatus
    displayFulfillmentStatus
    note
    customAttributes {
      key
      value
    }
    tags
    refunds(first: 50) {
      id
      createdAt
      updatedAt
      note
      staffMember {
        id
      }
      refundLineItems(first: 50) {
        nodes {
          id
          quantity
          restockType
          location {
            id
            name
          }
          totalTaxSet {
            ...MoneyBag
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      refundShippingLines(first: 50) {
        nodes {
          id
          shippingLine {
            id
          }
          subtotalAmountSet {
            ...MoneyBag
          }
          taxAmountSet {
            ...MoneyBag
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}

fragment MoneyBag on MoneyBag {
  presentmentMoney {
    amount
    currencyCode
  }
  shopMoney {
    amount
    currencyCode
  }
}`,
    variables: { id: "gid://shopify/Order/123" },
  },
};
