import "./App.css";
import "graphiql/graphiql.css";
import "@shopify/polaris/build/esm/styles.css";
import "@graphiql/plugin-explorer/dist/style.css";

import { useState, useCallback, useEffect, useMemo, useReducer } from "react";
import {
  Page,
  Button,
  TextField,
  Select,
  Checkbox,
  FooterHelp,
  Link,
  FormLayout,
  Card,
  InlineStack,
  BlockStack,
  InlineGrid,
  Text,
  ButtonGroup,
} from "@shopify/polaris";
import { ClipboardMinor, PageDownMajor } from "@shopify/polaris-icons";
import { decodeJwt } from "jose";
import ReactJson from "react-json-view";
import { GraphiQLProvider, useEditorContext } from "@graphiql/react";
import { GraphiQL, GraphiQLInterface } from "graphiql";
import { explorerPlugin } from "@graphiql/plugin-explorer";
import shopifyGraphQLSchema from "./shopifyGraphQLSchema.json";

import { useCreateToast } from "./OverlayManager";
import DataBrowser from "./DataBrowser";
import { PRESET_QUERIES } from "./gqlQueries";

const ENDPOINT_BASE = process.env.REACT_APP_ENDPOINT_BASE;

const API_VERSION = "2025-01"; // update MINIMUM_API_VERSION in backend/index.js

function TokenMagic({ setToken }) {
  const [localToken, setLocalToken] = useState(null);
  const [needsToFetchToken, setNeedsToFetchToken] = useState(false);

  // check for an existing token
  useEffect(() => {
    if (localToken == null) {
      try {
        const googleSignInToken = localStorage.getItem("googleSignInToken");
        if (!googleSignInToken) {
          setNeedsToFetchToken(true);
          console.log("cant find token");
          return;
        } else {
          const { exp } = decodeJwt(googleSignInToken);
          if (new Date() / 1000 + 30 > exp) {
            setNeedsToFetchToken(true);
            console.log("token expired");
            return;
          }
          console.log(`using token that is still good for ${exp - new Date() / 1000} seconds`);
          setLocalToken(googleSignInToken);
        }
      } catch (ex) {
        setNeedsToFetchToken(true);
        console.log("bonk", ex);
      }
    } else {
      try {
        localStorage.setItem("googleSignInToken", localToken);
        setToken(localToken);
      } catch (ex) {}
    }
  }, [localToken, setToken]);

  // remove expired tokens
  useEffect(() => {
    let hasRun = false;
    console.log("localToken", localToken);
    if (localToken) {
      try {
        const { exp } = decodeJwt(localToken);
        const delay = Math.max(1000, (exp - 30) * 1000 - new Date());
        console.log(`setting timeout that nulls out localToken in ${delay}ms`);
        const timeout = setTimeout(() => {
          hasRun = true;
          console.log("token has expired, clearing out localToken");
          setLocalToken(null);
          setNeedsToFetchToken(true);
        }, delay);
        return () => {
          if (!hasRun) {
            console.log("clearing timeout that nulls out localToken");
          }
          clearTimeout(timeout);
        };
      } catch (ex) {
        console.log("expired jwt timer setup", ex);
      }
    }
    return () => {};
  }, [localToken]);

  // try to get a new token
  useEffect(() => {
    if (needsToFetchToken) {
      const script = document.createElement("script");
      const div = document.createElement("div");
      script.async = true;
      script.src = "https://accounts.google.com/gsi/client";

      [
        ["id", "g_id_onload"],
        ["data-client_id", "773248862236-p6hj23g1m32tmam0v9b7kts0vq5cr73a.apps.googleusercontent.com"],
        ["data-context", "signin"],
        ["data-ux_mode", "popup"],
        ["data-callback", "googleSignInCallback"],
        ["data-auto_prompt", "true"],
        ["data-auto_select", "true"],
        ["data-itp_support", "true"],
        ["data-use_fedcm_for_prompt", "true"],
      ].forEach(([key, value]) => div.setAttribute(key, value));

      document.head.appendChild(script);
      document.body.appendChild(div);
      window.googleSignInCallback = ({ credential }) => {
        setNeedsToFetchToken(false);
        setLocalToken(credential);
      };
      return () => {
        script.remove();
        div.remove();
      };
    } else return () => {};
  }, [setToken, needsToFetchToken]);

  return needsToFetchToken ? (
    <div
      className="g_id_signin"
      data-type="standard"
      data-shape="rectangular"
      data-theme="outline"
      data-text="signin"
      data-size="large"
      data-logo_alignment="left"
    ></div>
  ) : null;
}

async function getAppLoginUrl(token, app, hostname, reason) {
  const url = new URL(`${ENDPOINT_BASE}/store_login`, window.location);
  url.searchParams.append("app", app);
  url.searchParams.append("hostname", hostname);
  url.searchParams.append("reason", reason);

  const response = await fetch(url, {
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: "",
  });
  const { redirect } = await response.json();
  return redirect;
}

const INITIAL_STATE = {
  apps: null,
  token: null,
  debugText: "",
  app: "",
  inputHostname: "",
  reason: "",
  locked: false,
  sid: null,
  discountCode: null,
  scopes: "",
  generatedUrl: "",
  contents: "",
  codeSignature: "",
  planId: "1",
  percentOff: "0",
  trialDays: "0",
  expiryDate: new Date(+new Date() + 7 * 24 * 3600 * 1000).toISOString().replace(/T.*/, ""),
  totalUses: "1",
  chargeName: "",
  chargePrice: "0",
  chargeIsTest: true,
  chargeConfirmationUrl: "",

  hostname: null,
};

function CopyButton({ value }) {
  const createToast = useCreateToast();

  return (
    <Button
      onClick={() => {
        navigator.clipboard.writeText(typeof value === "function" ? value() : value).then(() => {
          createToast({ content: "Copied!" });
        });
      }}
      label="Copy"
      icon={ClipboardMinor}
    />
  );
}

function DownloadButton({ value }) {
  const [url, setUrl] = useState("about:blank");
  const updateUrl = useCallback(() => {
    const content = typeof value === "function" ? value() : value;
    const blob = new Blob([content], { type: "application/json; charset=utf-8" });

    setUrl((old) => {
      if (old) {
        URL.revokeObjectURL(old);
      }
      return URL.createObjectURL(blob);
    });
  }, [value]);

  return (
    <span onMouseEnter={updateUrl} style={{ display: "contents" }}>
      <Button url={url} target="_blank" label="Download" icon={PageDownMajor} />
    </span>
  );
}

function PrettyOrText({ value }) {
  const parsedObject = useMemo(() => {
    try {
      return JSON.parse(value);
    } catch {
      return null;
    }
  }, [value]);
  return parsedObject ? <ReactJson src={parsedObject} /> : <pre>{value}</pre>;
}

function TitleCard({ title, children }) {
  return (
    <Card>
      <BlockStack gap="400">
        <Text as="h2" variant="headingSm">
          {title}
        </Text>
        {children}
      </BlockStack>
    </Card>
  );
}

const explorer = explorerPlugin({
  colors: {
    keyword: "hsl(var(--color-primary))",
    def: "hsl(var(--color-tertiary))",
    property: "hsl(var(--color-info))",
    qualifier: "hsl(var(--color-secondary))",
    attribute: "hsl(var(--color-tertiary))",
    number: "hsl(var(--color-success))",
    string: "hsl(var(--color-warning))",
    builtin: "hsl(var(--color-success))",
    string2: "hsl(var(--color-secondary))",
    variable: "hsl(var(--color-secondary))",
    atom: "hsl(var(--color-tertiary))",
  },
  styles: {
    buttonStyle: {
      backgroundColor: "transparent",
      border: "none",
      color: "hsla(var(--color-neutral), var(--alpha-secondary, 0.6))",
      cursor: "pointer",
      fontSize: "1em",
    },
    explorerActionsStyle: {
      padding: "var(--px-8) var(--px-4)",
    },
    actionButtonStyle: {
      backgroundColor: "transparent",
      border: "none",
      color: "hsla(var(--color-neutral), var(--alpha-secondary, 0.6))",
      cursor: "pointer",
      fontSize: "1em",
    },
  },
});

function GraphiQLTab({ app, hostname, reason, dismiss }) {
  const editorContext = useEditorContext();

  return (
    <div style={{ height: "100vh", display: "flex", flexFlow: "column" }}>
      <div style={{ padding: "0.1rem 0.2rem", position: "relative" }}>
        <InlineGrid columns="1fr auto">
          <ButtonGroup>
            {Object.values(PRESET_QUERIES).map(({ name, query, variables }) => (
              <Button
                key={name}
                onClick={() => {
                  editorContext.queryEditor.setValue(query);
                  editorContext.variableEditor.setValue(JSON.stringify(variables, null, 2));
                }}
                variant="tertiary"
                size="slim"
              >
                {name}
              </Button>
            ))}
          </ButtonGroup>
          <Button variant="primary" tone="critical" size="slim" onClick={dismiss}>
            Close
          </Button>
        </InlineGrid>
      </div>
      <div className="graphiql-container" style={{ flex: "1 0 0%", position: "relative" }}>
        <GraphiQLInterface defaultEditorToolsVisibility="variables">
          <GraphiQL.Logo>{hostname}</GraphiQL.Logo>
          <GraphiQL.Footer>
            <div style={{ marginTop: "0.25rem" }}>
              <InlineGrid columns="1fr auto">
                <p>
                  <b>
                    {app}@{hostname}
                  </b>
                  : {reason}
                </p>
                <InlineStack gap="100">
                  <CopyButton value={() => editorContext.responseEditor.getValue()} />
                  <DownloadButton value={() => editorContext.responseEditor.getValue()} />
                </InlineStack>
              </InlineGrid>
            </div>
          </GraphiQL.Footer>
        </GraphiQLInterface>
      </div>
    </div>
  );
}

//
// Administrative Stuff
//

function ToolsCard({ data, dispatch, gqlFetch }) {
  const {
    apps,
    token,
    app,
    reason,
    discountCode,
    scopes,
    generatedUrl,
    contents,
    codeSignature,
    planId,
    percentOff,
    trialDays,
    expiryDate,
    totalUses,
    chargeName,
    chargePrice,
    chargeIsTest,
    chargeConfirmationUrl,

    hostname,
  } = data;

  const setDiscountCode = useCallback((value) => dispatch(["discountCode", value]), [dispatch]);
  const setScopes = useCallback((value) => dispatch(["scopes", value]), [dispatch]);
  const setGeneratedUrl = useCallback((value) => dispatch(["generatedUrl", value]), [dispatch]);
  const setContents = useCallback((value) => dispatch(["contents", value]), [dispatch]);
  const setCodeSignature = useCallback((value) => dispatch(["codeSignature", value]), [dispatch]);
  const setPlanId = useCallback((value) => dispatch(["planId", value]), [dispatch]);
  const setPercentOff = useCallback((value) => dispatch(["percentOff", value]), [dispatch]);
  const setTrialDays = useCallback((value) => dispatch(["trialDays", value]), [dispatch]);
  const setExpiryDate = useCallback((value) => dispatch(["expiryDate", value]), [dispatch]);
  const setTotalUses = useCallback((value) => dispatch(["totalUses", value]), [dispatch]);

  const setChargeName = useCallback((value) => dispatch(["chargeName", value]), [dispatch]);
  const setChargePrice = useCallback((value) => dispatch(["chargePrice", value]), [dispatch]);
  const setChargeIsTest = useCallback((value) => dispatch(["chargeIsTest", value]), [dispatch]);
  const setChargeConfirmationUrl = useCallback((value) => dispatch(["chargeConfirmationUrl", value]), [dispatch]);

  const fetchShopScopes = useCallback(async () => {
    const {
      data: {
        appInstallation: { accessScopes },
      },
    } = await gqlFetch({
      query: `{
        appInstallation {
          accessScopes {
            handle
          }
        }
      }`,
    });

    return accessScopes.map(({ handle }) => handle);
  }, [gqlFetch]);

  return (
    <BlockStack gap="400">
      <Card>
        <Button
          onClick={async () => {
            window.open(await getAppLoginUrl(token, app, hostname, reason));
          }}
        >
          Login to {apps?.find?.(({ handle }) => handle === app)?.name ?? app}
        </Button>
      </Card>
      <TitleCard title="Discount Codes">
        <BlockStack gap="400">
          <FormLayout>
            <FormLayout.Group condensed>
              <TextField label="Plan ID" type="number" value={planId} onChange={setPlanId} />
              <TextField label="Percentage Off" type="number" value={percentOff} onChange={setPercentOff} />
              <TextField label="Trial Days" type="number" value={trialDays} onChange={setTrialDays} />
              <TextField label="Expiry Date" type="date" value={expiryDate} onChange={setExpiryDate} />
              <TextField label="Total Uses" type="number" value={totalUses} onChange={setTotalUses} />
            </FormLayout.Group>
          </FormLayout>

          <Button
            onClick={async () => {
              const url = new URL(`${ENDPOINT_BASE}/api/generate_discount_code`, window.location);

              url.searchParams.append("app", app || "");
              url.searchParams.append("plan_id", planId || "");
              url.searchParams.append("percent_off", percentOff || "");
              url.searchParams.append("trial_days", trialDays || "");
              url.searchParams.append("expiry_date", expiryDate || "");
              url.searchParams.append("total_uses", totalUses || "");
              url.searchParams.append("shop", hostname || "");
              url.searchParams.append("reason", reason || "");

              const response = await fetch(url, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              if (response.ok) {
                const { code } = await response.json();
                setDiscountCode(code);
              } else {
                setDiscountCode("Something went wrong...");
                console.error(`generate_discount_code error:\n${response.status}\n---\n${await response.text()}`);
              }
            }}
          >
            Generate Discount Code
          </Button>

          <TextField
            readOnly
            monospaced
            label="Discount Code"
            value={discountCode || ""}
            connectedRight={<CopyButton value={discountCode || ""} />}
          />
        </BlockStack>
      </TitleCard>
      <Card>
        <BlockStack gap="400">
          <TextField value={scopes} onChange={setScopes}></TextField>
          <InlineStack gap="400">
            <Button
              monochrome
              onClick={async () => {
                const s = await fetchShopScopes();
                setScopes(s.join(","));
              }}
            >
              Fetch Scopes
            </Button>
            <Button
              onClick={async () => {
                try {
                  const url = new URL(`${ENDPOINT_BASE}/api/generate_authorize_url`, window.location);
                  url.searchParams.append("app", app);
                  url.searchParams.append("hostname", hostname);
                  url.searchParams.append("reason", reason);
                  url.searchParams.append("scope", scopes);

                  const response = await fetch(url, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });
                  const { url: newGeneratedUrl } = await response.json();
                  setGeneratedUrl(newGeneratedUrl);
                } catch {
                  setGeneratedUrl("about:blank");
                }
              }}
              disabled={!scopes}
            >
              Generate Authorize URL
            </Button>
          </InlineStack>
          <TextField value={generatedUrl} readOnly connectedRight={<CopyButton value={generatedUrl} />}></TextField>
        </BlockStack>
      </Card>
      <TitleCard title="Sign Code">
        <BlockStack gap="400">
          <TextField multiline={5} monospaced value={contents} onChange={setContents}></TextField>
          <Button
            onClick={async () => {
              const url = new URL(`${ENDPOINT_BASE}/api/get_sign_sig`, window.location);
              url.searchParams.append("app", app);
              url.searchParams.append("hostname", hostname);
              url.searchParams.append("reason", reason);

              const response = await fetch(url, {
                method: "post",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ contents }),
              });
              const { signature: newCodeSignature } = await response.json();
              setCodeSignature(newCodeSignature);
            }}
            disabled={!contents}
          >
            Sign it
          </Button>
          <TextField value={codeSignature} readOnly connectedRight={<CopyButton value={codeSignature} />} />
        </BlockStack>
      </TitleCard>

      <TitleCard title="Create Charge">
        <FormLayout>
          <FormLayout.Group>
            <TextField label="Name" value={chargeName} onChange={setChargeName}></TextField>
            <TextField label="Price" type="currency" value={chargePrice} onChange={setChargePrice}></TextField>
          </FormLayout.Group>
          <Checkbox label="Test Charge" checked={chargeIsTest} onChange={setChargeIsTest}></Checkbox>
          <Button
            onClick={async () => {
              const proxyResponse = await gqlFetch({
                query: `mutation AppPurchaseOneTimeCreate($name: String!, $price: MoneyInput!, $returnUrl: URL!, $test: Boolean!) {
                appPurchaseOneTimeCreate(name: $name, returnUrl: $returnUrl, price: $price, test: $test) {
                  userErrors {
                    field
                    message
                  }
                  appPurchaseOneTime {
                    createdAt
                    id
                  }
                  confirmationUrl
                }
              }`,
                variables: {
                  name: chargeName,
                  returnUrl: "https://admin.shopify.com/",
                  price: {
                    amount: chargePrice,
                    currencyCode: "USD",
                  },
                  test: chargeIsTest,
                },
              });

              const {
                data: {
                  appPurchaseOneTimeCreate: { confirmationUrl },
                },
              } = proxyResponse;

              setChargeConfirmationUrl(confirmationUrl ?? "Something went wrong...");

              console.log(data);
            }}
            disabled={!(chargeName && chargePrice)}
          >
            Create Charge
          </Button>
          <TextField
            label="Charge Confirmation URL"
            readOnly
            value={chargeConfirmationUrl}
            connectedRight={<CopyButton value={chargeConfirmationUrl} />}
          />
        </FormLayout>
      </TitleCard>
    </BlockStack>
  );
}

// ////////////////////////////////////////////
//
//
// App Root
//
//
// ////////////////////////////////////////////

function App() {
  const [data, dispatch] = useReducer((last, [key, value]) => {
    const next = { ...last, [key]: value };

    if (key === "app" || key === "inputHostname" || key === "reason") {
      next.sid = "";
      next.discountCode = "";
      next.generatedUrl = "";
      next.codeSignature = "";
    }

    if (key === "planId" || key === "percentOff" || key === "trialDays" || key === "expiryDate" || key === "totalUses") {
      next.discountCode = "";
    }

    if (key === "scopes") {
      next.generatedUrl = "";
    }

    if (key === "contents") {
      next.codeSignature = "";
    }

    if (last.inputHostname !== next.inputHostname) {
      const match = /^([a-z0-9-]{3,})(?:\.myshopify\.com)?$/i.exec(next.inputHostname);
      next.hostname = match ? `${match[1]}.myshopify.com` : null;
    }

    return next;
  }, INITIAL_STATE);

  const { apps, token, debugText, app, inputHostname, reason, locked, hostname } = data;

  const setApps = useCallback((value) => dispatch(["apps", value]), []);
  const setToken = useCallback((value) => dispatch(["token", value]), []);
  const setDebugText = useCallback((value) => dispatch(["debugText", value]), []);
  const setApp = useCallback((value) => dispatch(["app", value]), []);
  const setInputHostname = useCallback((value) => dispatch(["inputHostname", value]), []);
  const setReason = useCallback((value) => dispatch(["reason", value]), []);
  const setLocked = useCallback((value) => dispatch(["locked", value]), []);

  const [mode, setMode] = useState("tools");
  const hideGraphiQl = useCallback(() => setMode("tools"), []);

  const tokenExpiry = useMemo(() => {
    try {
      const { exp } = decodeJwt(token);
      return new Date(exp * 1000);
    } catch {
      return null;
    }
  }, [token]);

  useEffect(() => {
    if (!apps && token) {
      fetch(`${ENDPOINT_BASE}/api/apps`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((r) => r.json())
        .then(({ apps: newApps }) => setApps(newApps));
    }
  }, [apps, setApps, token]);

  const appsOptions = useMemo(
    () => [
      { label: "-", value: "" },
      ...(apps
        ? apps.map(({ handle, name }) => ({
            label: name || handle,
            value: handle,
          }))
        : []),
    ],
    [apps]
  );

  const getProxyToken = useCallback(async () => {
    const url = new URL(`${ENDPOINT_BASE}/api/shopify_api`, window.location);
    url.searchParams.append("app", app || "");
    url.searchParams.append("shop", hostname || "");
    url.searchParams.append("reason", reason || "");
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw await response.text();
    }

    const { sid } = await response.json();
    return sid;
  }, [app, hostname, reason, token]);

  const gqlFetch = useCallback(
    async (graphQLParams) => {
      const proxyToken = await getProxyToken();

      const proxyUrl = new URL(`${ENDPOINT_BASE}/shopify_api/1/admin/api/${API_VERSION}/graphql.json`, window.location);

      const res = await fetch(proxyUrl, {
        method: "post",
        headers: {
          Authorization: `Bearer ${proxyToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          "Shopify-Search-Query-Debug": 1, // search debugging for weird situations
        },
        body: JSON.stringify(graphQLParams),
        credentials: "same-origin",
      });

      return res.json();
    },
    [getProxyToken]
  );

  // for graphqli
  const gqlFetcher = useCallback((graphQLParams) => gqlFetch(graphQLParams).catch((res) => res?.text?.() ?? res), [gqlFetch]);

  const hasNeededInfo = !!(hostname && app && reason) && tokenExpiry > +new Date();

  // prepopulate fields and superficially lock them from being edited
  useEffect(() => {
    const usp = new URLSearchParams(window.location.search);
    const urlHostname = usp.get("hostname");
    const urlApp = usp.get("app");
    const urlReason = usp.get("reason");

    if (urlHostname) {
      setInputHostname(urlHostname);
    }

    if (urlApp) {
      setApp(urlApp);
    }

    if (urlReason) {
      setReason(urlReason);
    }

    if (urlHostname && urlApp && urlReason) {
      setLocked(true);
    }
  }, [setApp, setInputHostname, setLocked, setReason]);

  // perform an autologin once we have everything we need
  useEffect(() => {
    const usp = new URLSearchParams(window.location.search);
    const urlAutoLogin = usp.get("autologin");

    if (token && app && hostname && reason && locked && urlAutoLogin === "1") {
      getAppLoginUrl(token, app, hostname, reason).then((url) => (window.location = url));
    }
  }, [token, app, hostname, reason, locked]);

  return (
    <>
      {mode !== "graphiql" ? (
        <Page>
          {token ? (
            <BlockStack gap="400">
              <Card>
                <InlineGrid gap="400" columns={3}>
                  <Select disabled={locked} label="App" value={app} onChange={setApp} options={appsOptions} />
                  <TextField
                    disabled={locked}
                    label="Shop"
                    type="text"
                    value={inputHostname}
                    onChange={setInputHostname}
                    size=""
                  />{" "}
                  <TextField disabled={locked} label="Reason" type="text" value={reason} onChange={setReason} />
                </InlineGrid>
              </Card>

              <InlineGrid columns={"1fr auto"}>
                <ButtonGroup>
                  <Button pressed={mode === "tools"} onClick={() => setMode("tools")}>
                    Tools
                  </Button>
                  <Button pressed={mode === "browser"} onClick={() => setMode("browser")}>
                    Data Browser
                  </Button>
                </ButtonGroup>
                <Button pressed={mode === "graphiql"} onClick={() => setMode("graphiql")}>
                  GraphiQL
                </Button>
              </InlineGrid>

              {hasNeededInfo && mode === "tools" ? <ToolsCard data={data} dispatch={dispatch} gqlFetch={gqlFetch} /> : null}
              {hasNeededInfo && mode === "browser" ? (
                <DataBrowser data={data} dispatch={dispatch} gqlFetch={gqlFetch} getProxyToken={getProxyToken} />
              ) : null}
            </BlockStack>
          ) : (
            "Verifying Identity..."
          )}
          <FooterHelp>
            <Link
              onClick={() => {
                setLocked(false);
              }}
            >
              ©
            </Link>
            <Link
              onClick={() => {
                localStorage.removeItem("googleSignInToken");
                setToken(null);
              }}
            >
              2025
            </Link>{" "}
            ༼ つ{" "}
            <Link
              onClick={async () => {
                const url = new URL(`${ENDPOINT_BASE}/api/whoami`, window.location);

                const response = await fetch(url, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });
                setDebugText(await response.text());
              }}
              removeUnderline
            >
              <span title="Who am I?">◕</span>
            </Link>
            _
            <Link
              onClick={async () => {
                const url = new URL(`${ENDPOINT_BASE}/api/clear_cache`, window.location);

                const response = await fetch(url, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });
                setDebugText(await response.text());
              }}
              removeUnderline
            >
              <span title="Clear The Cache">◕</span>
            </Link>{" "}
            ༽つ
          </FooterHelp>
          <PrettyOrText value={debugText} />
          <FooterHelp>
            {tokenExpiry
              ? `This page expires ${new Intl.DateTimeFormat("en-CA", {
                  dateStyle: "full",
                  timeStyle: "long",
                }).format(tokenExpiry)}.`
              : null}
          </FooterHelp>
          <TokenMagic key="tokenMagic" setToken={setToken} />
        </Page>
      ) : null}
      <GraphiQLProvider
        fetcher={gqlFetcher}
        schema={shopifyGraphQLSchema.data}
        defaultQuery={"query Query {\n  shop {\n    myshopifyDomain\n  }\n}"}
        variables={"{\n  \n}"}
        plugins={[explorer]}
      >
        {hasNeededInfo && mode === "graphiql" ? (
          <GraphiQLTab gqlFetch={gqlFetch} app={app} hostname={hostname} reason={reason} dismiss={hideGraphiQl} />
        ) : null}
      </GraphiQLProvider>
    </>
  );
}

export default App;
